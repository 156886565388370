import { Outlet } from 'react-router';

import { Navigation } from '~/components/navigation/component';
import { AccountBanner } from '~/components/page/account-banner';
import { ShortTermClosureAlert } from '~/components/page/alert';
import { CutoffCountdown } from '~/components/page/cutoff-countdown';
import { Footer } from '~/components/page/footer';
import { getLoggedInUser } from '~/services/auth/functions';
import { getCart } from '~/services/cart.server';
import { getSignedInLinks } from '~/services/user.server';
import { formatUserForDisplay } from '~/services/user/functions';
import { getUserOrNull } from '~/services/user/queries';
import { getSession } from '~/utils/sessions';
import { getDisplayCutoffDate } from '~/utils/shipping';
import { isShutdownPeriod } from '~/utils/shutdown';

import type { Route } from './+types/route';

export async function loader({ request }: Route.LoaderArgs) {
  const session = await getSession(request.headers.get('Cookie'));

  const isChristmasShutdown = isShutdownPeriod();

  const userId = await getLoggedInUser(request, {
    shouldThrowWhenUnverified: false,
    shouldReturnUserWhenUnverified: false,
  });

  const user = await getUserOrNull({ id: userId });

  const roleSlugs = user?.userRoles.map((ur) => ur.role.slug) ?? [];
  const links = await getSignedInLinks(roleSlugs);

  return {
    cart: getCart(session),
    nextDeliveryDate: getDisplayCutoffDate().toISOString(),
    user: formatUserForDisplay(user),
    isChristmasShutdown,
    links,
  };
}

export default function Layout({ loaderData }: Route.ComponentProps) {
  const itemsInCart = loaderData.cart.items.length ?? 0;

  return (
    <div>
      <AccountBanner user={loaderData.user} links={loaderData.links} />
      <Navigation itemsInCart={itemsInCart} />

      {!loaderData.isChristmasShutdown ? (
        <CutoffCountdown date={loaderData.nextDeliveryDate} />
      ) : null}

      {loaderData.isChristmasShutdown ? <ShortTermClosureAlert /> : null}

      <Outlet />
      <Footer />
    </div>
  );
}
